import React, { Component } from 'react'
import Login from "../pages/Login"
export default class LoginPageContainer extends Component {

    render() {
        return (
            <div>
                <Login />
            </div>
        )
    }
}