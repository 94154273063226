import React, { Component } from 'react'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Context from '../Context'

import HomeContainer from '../containers/HomeContainer'
import LoginPageContainer from '../containers/LoginPageContainer'


export default class Routing extends Component {
    static contextType = Context

    render() {

        if (!this.context.isLoggedIn) {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={LoginPageContainer} />
                    </Switch>
                </BrowserRouter>
            )
        }
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={HomeContainer} />
                </Switch>
            </BrowserRouter>
        )
    }
}