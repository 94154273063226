import React, { Component } from 'react'
import "../styles/Navigation.scss"




export default class Naavigation extends Component {

    render() {
        return (
            <section class="navigation">
                <div class="nav-container">
                    <nav>
                        <div class="nav-mobile">
                            <ul class="nav-list">
                                <li>
                                    <a href="#!">Logout</a>
                                </li>
                            </ul>
                        </div>
                        <ul class="nav-list">
                            <li>
                                <a href="#!">Home</a>
                            </li>
                            <li>
                                <a href="#!">About</a>
                            </li>

                            <li>
                                <a href="#!">Logout</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </section>


        )
    }
}