import { put } from 'redux-saga/effects'

import { SET_TEST_LOGIN } from '../actions'

export function* testLogin(action) {
    console.log("saga test", action)
    try {
        yield put({
            type: SET_TEST_LOGIN,
            payload: { password: action.payload.action.password }
        })
    }
    catch (error) {
        //
    }
}
