export const TEST_ACTION = 'TEST_ACTION'
export const SET_TEST_ACTION = 'TEST_ACTION'


export const RESET_CONTACT_FORM = "RESET_CONTACT_FORM"
export const GET_CONTACT_FORM = "GET_CONTACT_FORM"
export const SET_CONTACT_FORM = "SET_CONTACT_FORM"


export const GET_TEST_LOGIN = "GET_TEST_LOGIN"
export const SET_TEST_LOGIN = "SET_TEST_LOGIN"