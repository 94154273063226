import { SET_TEST_LOGIN } from '../actions'

const initialState = {
    testLogin: false,
}

export default (state = initialState, action) => {
    if (action.type === SET_TEST_LOGIN) {
        const password = action.payload.password
        console.log("password", password)
        const actualPassword = process.env.REACT_APP_PASSWORD
        if (password !== actualPassword) {
            console.log("invalid passsword")
            state = {
                ...state,
                testLogin: false,
            }
        } else {
            console.log("passsword correct")
            state = {
                ...state,
                testLogin: true,
            }
        }

    }

    return state
}

// 1GoTGxpcLUzvvn9c4eJ4mJlcEL
