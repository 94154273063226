import { all, takeLatest } from 'redux-saga/effects'

import {
    SET_TEST_ACTION, GET_CONTACT_FORM, RESET_CONTACT_FORM, GET_TEST_LOGIN
} from '../actions'

import { testAction } from './TestSaga';
import { contactForm, contactFormReset } from './ContactSaga';
import { testLogin } from "./TestLoginSaga"

export default function* () {
    yield all([
        takeLatest(SET_TEST_ACTION, testAction),
        takeLatest(GET_CONTACT_FORM, contactForm),
        takeLatest(RESET_CONTACT_FORM, contactFormReset),
        takeLatest(GET_TEST_LOGIN, testLogin)

    ])
}