import { combineReducers } from 'redux'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import Test from './TestReducer'
import ContactForm from './ContactReducer'
import TestLogin from './TestLoginReducer'



const rootPersistConfig = {
    key: 'root',
    storage,
    blacklist: [
    ]
}

const reducers = combineReducers({
    Test, ContactForm, TestLogin
})

export default persistReducer(rootPersistConfig, reducers)