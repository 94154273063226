import React, { Component } from 'react'
import Intro from "../sections/Intro"
import About from "../sections/About"
import Contact from "../sections/Contact"
import NavigationContainer from '../containers/NavigationContainer';
import { testLogin } from "../state/actions/TestLoginAction"
import $ from 'jquery';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import "../styles/HomePage.scss"

class HomePage extends Component {
    logOut = () => {
        const payload = {
            password: "0000"
        }
        this.props.testLogin(payload)

    }
    componentDidMount() {
        var a = $(".text");
        var b = $(".hr");
        var c = $(".descText");
        // a.delay(1000).animate({ top: "20px", opacity: "1" }, 1000, function () {
        //     b.delay(500).animate({ marginLeft: "0" }, 800, function () {
        //         c.animate({ marginTop: "-10px" }, 800);
        //     });
        // });
    }
    render() {
        return (
            <div id="homeDiv">
                <Intro />
                <About />
                <Contact />
                <span onClick={this.logOut} className="logoutButton">Logout</span>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    state
})

const mapDispatchToProps = dispatch => bindActionCreators({
    testLogin
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(HomePage)