import React, { Component } from 'react'
import { Form, Input, Select, Icon, Alert, Button, } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { testLogin } from "../state/actions/TestLoginAction"
import "../styles/Login.scss"

class Login extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            submitting: false,
            sent: false
        }
    }
    componentDidMount() {
        this._isMounted = true;
    }
    handleSubmit = (e, values) => {

        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {


            } else {
                console.log("submittoong", values)
                this.props.testLogin(values)
            }
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form className="login-form" onSubmit={this.handleSubmit} >
                <div style={{ marginBottom: 20 }} >
                    <h3>Login To Continue</h3>
                </div>

                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input your Password!' }],
                    })(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                    )}
                </Form.Item>
                <Form.Item>

                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                  </Button>
                </Form.Item>
            </Form>
        )
    }
}
const mapStateToProps = state => ({
    state
})

const mapDispatchToProps = dispatch => bindActionCreators({
    testLogin
}, dispatch)

const LoginForm = Form.create()(Login);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)