import React, { Component } from 'react'
import Navigation from "../components/Navigation"
export default class NavigationContainer extends Component {

    render() {
        return (
            <div>
                <Navigation />
            </div>
        )
    }
}