import { SET_TEST_ACTION } from '../actions'

const initialState = {
    testReducer: null,
}

export default (state = initialState, action) => {
    if (action.type === SET_TEST_ACTION) {

        console.log("Test reducer")
        state = {
            ...state,
        }
    }

    return state
}

// 1GoTGxpcLUzvvn9c4eJ4mJlcEL
