import React, { Component } from 'react'
import HomePage from "../pages/HomePage"
export default class TestContainer extends Component {

    render() {
        return (
            <div>
                <HomePage />
            </div>
        )
    }
}