import { put } from 'redux-saga/effects'

import { SET_TEST_ACTION } from '../actions'

export function* testAction(action) {
    console.log("saga test")
    try {
        yield put({
            type: SET_TEST_ACTION,
            payload: {}
        })
    }
    catch (error) {
        //
    }
}
