import React, { Component } from 'react'
import { Form, Input, Select, Alert, Button, } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { contactForm, contactFormReset } from "../state/actions/ContactAction"
import '../styles/Contact.scss'

const { TextArea } = Input;
const { Option } = Select;


class ContactComponent extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            submitting: false,
            sent: false
        }
    }
    componentDidMount() {
        this._isMounted = true;
    }
    handleSubmit = (e, values) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
            } else {
                this.setState({
                    submitting: true,
                    error: false
                })
                this.props.contactForm(values)
            }
        });
    }

    finalize = () => {
        this.props.contactFormReset()
    }
    componentWillReceiveProps(newProps) {
        const { sent } = newProps.ContactFormState
        if (sent) {
            this.setState({
                submitting: false,
                error: false,
                sent: true
            })

        } else {
            this.setState({
                sent: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { submitting, sent } = this.state
        return (
            <div className="sectionContainer">
                <div className="Section">
                    <Form className="contactForm" onSubmit={this.handleSubmit} >
                        <div style={{ marginBottom: 0 }} >
                            <h3>Contact Us</h3>

                            <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)' }} >
                                {getFieldDecorator('first_name', {
                                    rules: [{ required: true, message: ' ', whitespace: true }],
                                })(
                                    <Input className="contactFormInput" placeholder="First Name" />
                                )}
                            </Form.Item>
                            <Form.Item style={{ display: 'inline-block', float: "right", width: 'calc(50% - 12px)' }} >
                                {getFieldDecorator('last_name', {
                                    rules: [{ required: true, message: ' ', whitespace: true }],
                                })(
                                    <Input className="contactFormInput" placeholder="Last Name" />
                                )}
                            </Form.Item>
                        </div>
                        <Form.Item  >
                            {getFieldDecorator('email', {
                                rules: [{
                                    type: 'email', message: 'The input is not valid E-mail!',
                                }, {
                                    required: true, message: ' ',
                                }],
                            })(
                                <Input className="contactFormInput" placeholder="Email" />
                            )}
                        </Form.Item>
                        <Form.Item
                        >
                            {getFieldDecorator('phone', {
                                rules: [{ required: true, message: ' ' }],
                            })(
                                <Input className="contactFormInput" type="number" placeholder="Phone" style={{ width: '100%' }} />
                            )}
                        </Form.Item   >
                        <Form.Item
                        >
                            {getFieldDecorator('comments', {

                            })(
                                <TextArea className="contactFormInput" style={{ height: "45px" }} placeholder="Comments" autosize />
                                // <Input className="contactFormInput" type="number" placeholder="Phone" style={{ width: '100%' }} />
                            )}
                        </Form.Item   >
                        <div style={{ marginBottom: 0 }} >
                            <Form.Item
                                style={{
                                    display: 'inline-block',
                                    width: 'calc(55% - 12px)'
                                }}  >
                                {getFieldDecorator('bedrooms', {
                                    rules: [{
                                        required: true, message: ' ',
                                    }],
                                })(
                                    <Select className="contactSelectResidence" placeholder="Residence Size">
                                        <Option value={0}>Studios</Option>
                                        <Option value={1}>One Bedrooms</Option>
                                        <Option value={2}>Two Bedrooms</Option>
                                        <Option value={3}>Three Bedrooms</Option>
                                        <Option value={4}>Four Bedrooms</Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item
                                style={{
                                    display: 'inline-block',
                                    float: "right",
                                    width: 'calc(45% )'
                                }}  >
                                {getFieldDecorator('broker', {
                                    rules: [{
                                        required: true, message: ' ',
                                    }],
                                })(
                                    <Select className="contactSelectResidence" placeholder="Are You A Broker">
                                        <Option value="No">No</Option>
                                        <Option value="Yes">Yes</Option>
                                    </Select>
                                )}
                            </Form.Item>

                        </div>
                        {sent ?
                            <Alert message="Message Sent" type="success" closable onClose={this.finalize} /> :

                            null
                        }
                        <Form.Item style={{ marginTop: 20 }}>

                            <Button loading={submitting} className="contactSubmit" type="primary" htmlType="submit">SUBMIT</Button>
                        </Form.Item>

                        {/* <Form.Item>
                            <Button className="contactSubmit" onClick={this.finalize}>Clear</Button>
                        </Form.Item> */}
                    </Form >
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    ContactFormState: state.ContactForm
})

const mapDispatchToProps = dispatch => bindActionCreators({
    contactForm, contactFormReset
}, dispatch)

const ContactComponentForm = Form.create()(ContactComponent);

export default connect(mapStateToProps, mapDispatchToProps)(ContactComponentForm)