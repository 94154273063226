import React, { Component } from 'react'
import '../styles/Intro.scss'
import introBG from "../assets/bg.jpg"

export default class IntroPage extends Component {

    render() {
        return (
            <div style={{ backgroundImage: `url(${introBG})` }} id="introContainer">
                <div className="middle">
                    <div className="cutoff">
                        <p className="text">181</p>
                        <div className="hr" />
                        <p className="desc">
                            <span className="descText">
                                28
                        </span>
                            <span className="descText hillrose">
                                HILLROSE
                        </span>
                        </p>
                    </div>
                </div>
            </div>


        )
    }
}