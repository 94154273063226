import { GET_TEST_LOGIN } from './'

export const testLogin = (action) => {
    console.log("testing login", action)
    return {
        type: GET_TEST_LOGIN,
        payload: {
            action
        }
    }
}

