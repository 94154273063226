import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import App from '../components/App'
import Context from '../Context'

class AppContainer extends Component {

    render() {
        const { testLogin } = this.props.state
        const contextValues = {
            isLoggedIn: !!testLogin
        }
        return (
            <Context.Provider value={contextValues}>
                <App />
            </Context.Provider>
        )
    }
}

const mapStateToProps = (state) => ({
    state: state.TestLogin
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)