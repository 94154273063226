import React, { Component } from 'react'
import '../styles/About.scss'

export default class AboutSections extends Component {

    render() {
        return (
            <div className="Section">
                <h4>About Hillrose 28</h4>
                <br />
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis dui tortor. Quisque non sapien placerat magna hendrerit condimentum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id diam sem. Vestibulum dignissim quam nec massa pellentesque, a condimentum mauris maximus. Cras pretium elit a enim egestas tempor. Praesent sed mi in arcu tempus facilisis ut sit amet turpis. Maecenas dignissim, quam et pharetra commodo, erat ligula lacinia elit, vitae venenatis ipsum erat a odio. Sed ac dolor quis felis egestas porttitor. Quisque vel lorem in enim venenatis dignissim nec eget metus. Etiam imperdiet dui nec vulputate molestie. Vestibulum vel sapien felis. Donec dictum urna non est malesuada, nec ultrices nisl tristique.</p>
            </div>
        )
    }
}